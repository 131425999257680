<template>
  <div v-if="canAccess('rp_assignment_country')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="p-col-12">
      <h6>Informe Asiganción por mes</h6>
    </div>
    <div class="p-col-12">
      <div class="p-text-right p-fluid  p-ml-2">
        <div class="p-grid p-fluid  p-ml-2">
          <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"/>
                      <label for="country">País</label>
                  </span>
          </div>

          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df" style="margin-right: 3px">
                          <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                                    id="start_date" :showIcon="true" v-model="date_start"
                                    dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2019:2040"/>
                          <label for="start_date">Fecha Inicio</label>
            </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Buscar" class="p-button-success  df"
                    @click="getData()"/>
          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Exportar" class="p-button-secondary df"
                    @click="exportReport('XLS')"/>

          </div>
          <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
            <Button label="Imprimir" class="p-button-info df"
                    @click="exportReport('PDF')"/>

          </div>

        </div>
      </div>
    </div>


    <div class="card" v-for="(dataMonthClosePayment, subsidiary) in dataMonthClosePayments" :key="subsidiary">
      <div class="table-responsive">

        <h5><b>{{ subsidiary }}</b></h5>

        <table class="table table-hover" style="width: 100%">
          <thead>
          <tr>
            <th class="text-center thDescriptionHead"><small><b>EQUIPO</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>CUENTAS ASIGN.</b></small></th>
            <th class="text-center thDescriptionHead" colspan='2'><small><b>GENERAL</b></small></th>
            <th class="text-center thDescriptionHead" colspan='3'><small><b>COBRANZA PRESENCIAL</b></small></th>
            <th class="text-center thDescriptionHead" colspan='3'><small><b>COBRANZA ONLINE</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>VALOR A COBRAR</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>VALOR ANTICIPADO <br/>MES
              ANTERIOR</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>NETO A COBRAR</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>V. Cobr NORMAL</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>PRIMERA CUOTA</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>CUOTA COBRADO</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>%</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>PF</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>PJ</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='3'><small><b>R.B.</b></small></th>
          </tr>
          <tr>
            <th class="text-center thDescriptionHead" rowspan='2'><small><b>EJECUTIVOS</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='2'><small><b>COB</b></small></th>
            <th class="text-center thDescriptionHead" rowspan='2'><small><b>CTAS</b></small></th>
            <th class="text-center thDescriptionHead" colspan='2'><small><b>CUENTAS</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>%</b></small></th>
            <th class="text-center thDescriptionHead" colspan='2'><small><b>CUENTAS</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>%</b></small></th>
          </tr>
          <tr>
            <th class="text-center thDescriptionHead"><small><b>Asig.</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>Cob.</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>%</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>Asig.</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>Cob.</b></small></th>
            <th class="text-center thDescriptionHead"><small><b>%</b></small></th>
          </tr>
          </thead>
          <tbody class="text-center tableBody">
          <tr v-for="(itemExecutive, keyDataMonthClosePayment) in dataMonthClosePayment"
              :key="'tr_'+keyDataMonthClosePayment">
            <td class="tdDescription"><small>{{ itemExecutive.executive }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.accounts_assigments }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.general_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.general_cta }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.presencial_assigments }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.presencial_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.presencial_cta }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.online_assigments }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.online_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.online_cta }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.value_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.value_ant_mont }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.neto_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.value_cob_normal }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.first_fee_assigments }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.first_fees_cob }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.percentage_first_fee }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.payment_finish }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.payment_pj }}</small></td>
            <td class="tdDescription"><small>{{ itemExecutive.payment_rb }}</small></td>
          </tr>
          <tr>
            <td class="tdDescription"><small><b>TOTALES</b></small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'accounts_assigments') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'general_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClosePercentage(dataMonthClosePayment,'accounts_assigments','general_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'presencial_assigments') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'presencial_cob') }}</small></td>
            <td class="tdDescription"><small>{{  sumTotalMonthClosePercentage(dataMonthClosePayment,'presencial_assigments','presencial_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'online_assigments') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'online_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClosePercentage(dataMonthClosePayment,'online_assigments','online_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'value_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'value_ant_mont') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'neto_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'value_cob_normal') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'first_fee_assigments') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'first_fees_cob') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClosePercentage(dataMonthClosePayment,'first_fee_assigments','first_fees_cob')}}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'payment_finish') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'payment_pj') }}</small></td>
            <td class="tdDescription"><small>{{ sumTotalMonthClose(dataMonthClosePayment,'payment_rb') }}</small></td>
          </tr>
          </tbody>

        </table>
        <br/>
        <br/>
      </div>
    </div>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import catalogService from "../../core/service/catalog.service";
import moment from "moment";
import service from "../service/report.service";
import FileSaver from 'file-saver';

export default {
  mixins: [bouncer],
  name: "ResumePaymentReport",
  data() {
    return {
      loading: false,
      date_end: null,
      date_start: null,
      date_end_max: null,
      country: null,
      countries: [],
      resumeMonthCloses: [],
      service: service('month-close-payments'),
    }
  },
  mounted() {
    if (this.canAccess('rp_assignment_country')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);

      const date = new Date();
      this.date_end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_end_max = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_start = new Date(date.getFullYear(), date.getMonth(), 1);

      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      });

    }
  },
  methods: {
    formatMoney(valueToTransform) {
      return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })).format(valueToTransform);
    },
    sumTotalMonthClose(arrayToEvaluate, keyToSum) {
      let initialValue = 0;
      return arrayToEvaluate.reduce(function (total, currentValue) {
        return total + currentValue[keyToSum];
      }, initialValue);
    },
    sumTotalMonthClosePercentage(arrayToEvaluate, assignment,cob) {
      const totalAssignment=this.sumTotalMonthClose(arrayToEvaluate,assignment);
      const totalCob=this.sumTotalMonthClose(arrayToEvaluate,cob);
      return totalAssignment > 0 ? (( totalCob / totalAssignment ) * 100).toFixed(2) : 0
    },
    formatDate(dat) {
      return moment(dat).format('YYYY-MM-DD');
    },
    getCountries() {
      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
      });
    },
    exportReport(command) {
      if (this.country == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getMonthClose({
        date_start: moment(this.date_start).format("YYYY-MM-DD"),
        date_end: moment(this.date_end).format("YYYY-MM-DD"),
        country: this.country?.id,
        export: command
      })
          .then(x => {
            if (command == 'PDF') {
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            } else {
              this.loading = false;
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        this.loading = false;
        let error = this.$utils.formatError(err.response.data);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    },
    getData() {
      if (this.country == null && this.isAdmin) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
      } else {
        if (!moment(this.date_start).isValid() || !moment(this.date_end).isValid()) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Los campos de fecha son obligatorios para el reporte',
            life: this.$utils.toastLifeTime()
          });
        } else {
          if ((moment(this.date_start) > moment(this.date_end))) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
              life: this.$utils.toastLifeTime()
            });
          } else {
            this.loading = true;
            this.resumeMonthCloses = [];
            this.service.getMonthClose({
              date_start: moment(this.date_start).format("YYYY-MM-DD"),
              date_end: moment(this.date_end).format("YYYY-MM-DD"),
              country: this.country?.id,
            }).then(x => {
              this.loading = false;
              if (x.data.dataMonthClosePayments.length === 0) {
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No hay registros por visualizar con los valores escogidos',
                  life: this.$utils.toastLifeTime()
                });
              } else {
                this.dataMonthClosePayments = x.data.dataMonthClosePayments
              }

            }).catch(err => {
              this.loading = false;
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
            })
          }
        }
      }

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb'])
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC > div > div > table {
  table-layout: auto !important;
}

.df {
  margin-left: 3px !important;
  margin-right: 4px !important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled {
  height: 35px !important;
}


.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thDescriptionHead {
  background: #f8f9fa !important;
}

.tdDescription {
  padding: 4px !important;
  text-align: center;
}

.p-dropdown-label {
  text-align: left;
}
</style>
